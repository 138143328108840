const colors = {
	// black: '#111111',
	// white: '#FFFFFF',
	// primary: {
	// 	yellow: '#FFF2C5',
	// 	blue: '#E0F1FF',
	// 	purple: '#EBE4FE',
	// 	orange: '#FBE4E1',
	// },
	// gray: {
	// 	100: '#F8F9FA',
	// 	200: '#E9ECEF',
	// 	300: '#DEE2E6',
	// 	400: '#CED4DA',
	// 	500: '#ADB5BD',
	// 	600: '#6C757D',
	// 	700: '#495057',
	// 	800: '#343A40',
	// 	900: '#212529',
	// },
	primary: 'burlywood',
};

export default colors;
