import me from './me';
import { username, email, password } from './update';

const user = {
	me,
	update: {
		username,
		email,
		password,
	},
};

export default user;
